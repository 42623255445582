<template>
  <div class="panel-title">
    <i :class="[icon]"></i>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-title {
  display: flex;
  align-items: center;
  background: #ffffff;
  height: 60px;
  line-height: 60px;
  padding: 0px 25px;
  margin-bottom: 12px;
  i {
    font-size: 18px;
    margin-right: 4px;
    vertical-align: middle;
  }

  span {
    font-weight: bold;
  }
}
</style>