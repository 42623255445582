<template>
  <div class="panel-content">
    <div class="filter-wrap">
      <slot name="filter" />
    </div>
    <div class="content-wrap">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.panel-content {
  background: #ffffff;
  overflow: hidden;
  padding: 25px 25px 25px 25px;
  min-height: 650px;
  position: relative;

  .filter-wrap {
    margin-bottom: 18px;
  }
}
</style>